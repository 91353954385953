<template>
  <v-app class="admin-app-layout">
    <app-header />
    <app-drawer />

    <v-main>
      <slot />
    </v-main>

    <order-action-dialog />
    <global-snackbar />
    <global-email-viewer />
  </v-app>
</template>

<script>
import AppDrawer from '@/components/app-drawer'
import AppHeader from '@/components/app-header'
import GlobalEmailViewer from '@/components/admin/global-email-viewer'
import GlobalSnackbar from '@/components/global-snackbar'
import OrderActionDialog from '@/components/admin/order-action-dialog'

export default {
  name: 'admin-app-layout',
  components: {
    AppDrawer,
    AppHeader,
    GlobalEmailViewer,
    GlobalSnackbar,
    OrderActionDialog,
  },
}
</script>
