<template>
  <v-snackbar
    v-if="globalEmailViewer && email"
    v-model="showSnackbar"
    data-test="global-email-viewer"
    color="white"
    multi-line
    :timeout="-1"
    :location="location"
    content-class="py-0"
  >
    <div v-if="minimized" class="text-black">
      <div><span class="text-subtitle-2">Subject: </span>{{ email.subject }}</div>
    </div>
    <div v-else class="email-container">
      <div><span class="text-subtitle-2">From: </span>{{ email.from_address }}</div>
      <div><span class="text-subtitle-2">To: </span>{{ email.to_addresses }}</div>
      <div><span class="text-subtitle-2">Subject: </span>{{ email.subject }}</div>
      <safe-html-renderer :html="email.body" />
    </div>

    <template v-slot:actions>
      <v-btn-toggle
        borderless
        variant="dense"
        bg-color="transparent"
        class="left-actions-btn-toggle"
        active-class="actions-btn-toggle-active"
      >
        <v-tooltip v-if="showOrderBtn" location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <qtm-icon-btn icon="mdi-receipt" v-bind="props" size="x-small" @click="showOrder" />
          </template>
          <span>Open related order</span>
        </v-tooltip>

        <v-tooltip location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <qtm-icon-btn icon="mdi-email" v-bind="props" size="x-small" @click="showEmail" />
          </template>
          <span>Open Email</span>
        </v-tooltip>
      </v-btn-toggle>

      <v-btn-toggle
        borderless
        variant="dense"
        bg-color="transparent"
        class="actions-btn-toggle"
        active-class="actions-btn-toggle-active"
      >
        <v-tooltip location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <qtm-icon-btn v-bind="props" size="x-small" :icon="toggleIconMinimized" @click="toggleMinimized" />
          </template>
          <span>{{ minimizedTooltip }}</span>
        </v-tooltip>

        <v-tooltip location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <qtm-icon-btn v-bind="props" size="x-small" :icon="toggleIconY" @click="toggleY" />
          </template>
          <span>{{ toggleYTooltip }}</span>
        </v-tooltip>

        <v-tooltip location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <qtm-icon-btn v-bind="props" size="x-small" :icon="toggleIconX" @click="toggleX" />
          </template>
          <span>{{ toggleXTooltip }}</span>
        </v-tooltip>

        <v-tooltip location="bottom" z-index="9999">
          <template v-slot:activator="{ props }">
            <qtm-icon-btn v-bind="props" size="x-small" icon="mdi-close" @click="hideGlobalEmailViewer" />
          </template>
          <span>Close window</span>
        </v-tooltip>
      </v-btn-toggle>

      <dialog-view-email-detail v-if="openedEmail" v-model="openedEmail" />
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import DialogViewEmailDetail from '@/components/admin/emails/dialog-view-email-detail'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer'

export default {
  name: 'global-email-viewer',
  components: { DialogViewEmailDetail, SafeHtmlRenderer },
  data() {
    return {
      showSnackbar: false,
      position: {
        left: false,
        right: true,
        top: true,
        bottom: false,
      },
      minimized: false,
      openedEmail: null
    }
  },
  computed: {
    ...mapState('admin', { globalEmailViewer: 'globalEmailViewer' }),
    email() {
      return this.globalEmailViewer?.email
    },
    location() {
      const x = this.position.left ? 'left' : 'right'
      const y = this.position.top ? 'top' : 'bottom'
      return `${x} ${y}`
    },
    minimizedTooltip() {
      return this.minimized ? 'Restore window' : 'Minimize window'
    },
    showOrderBtn() {
      return !!this.email.rfq
    },
    toggleXTooltip() {
      return this.position.right ? 'Move window to the left' : 'Move window to the right'
    },
    toggleYTooltip() {
      return this.position.top ? 'Move window to the bottom' : 'Move window to the top'
    },
    toggleIconMinimized() {
      return this.minimized ? 'mdi-window-restore' : 'mdi-window-minimize'
    },
    toggleIconX() {
      return this.position.right ? 'mdi-chevron-left' : 'mdi-chevron-right'
    },
    toggleIconY() {
      return this.position.top ? 'mdi-chevron-down' : 'mdi-chevron-up'
    },
  },
  watch: {
    globalEmailViewer() {
      this.showSnackbar = this.globalEmailViewer?.show
    }
  },
  methods: {
    ...mapMutations({
      hideGlobalEmailViewer: 'admin/hideGlobalEmailViewer',
      activateOrder: 'admin/activateOrder',
    }),
    showEmail() {
      this.openedEmail = this.email
    },
    showOrder() {
      if (this.email && this.email.rfq) {
        this.activateOrder({ id: this.email.rfq })
      }
    },
    toggleMinimized() {
      this.minimized = !this.minimized
    },
    toggleX() {
      this.position.left = this.position.right
      this.position.right = !this.position.right
    },
    toggleY() {
      this.position.top = this.position.bottom
      this.position.bottom = !this.position.bottom
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-btn-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0;
}
.actions-btn-toggle-active::before {
  opacity: 0;
}
.actions-btn-toggle button, .left-actions-btn-toggle button {
  color: rgb(var(--v-theme-interactive));
  height: 24px !important;
}
.left-actions-btn-toggle {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0;
}
.email-body {
  margin-right: -15px;
  max-height: 400px;
  overflow-y: auto;
}
.email-container {
  color: black;
  max-height: 400px;
  max-width: 400px;
  padding-top: 18px;
  padding-bottom: 5px;
  overflow-y: auto;
}
</style>
